<template>
  <div :class="$style.card">
    <img
      v-if="obj.present"
      :class="$style.present__img"
      src="@/assets/icons/present.svg"
    />
    <div :class="$style.flex">
      <div :class="$style.period">{{ obj.period }}</div>
      <div :class="{ [$style.prices]: !obj.present }">
        <div v-if="obj.discount" :class="$style.discount">
          {{ obj.discount }}
        </div>
        <div :class="$style.price">{{ obj.rub }}</div>
        <div :class="$style.convert">
          <span :class="$style.bun">
            <span :class="$style.line">≈</span>{{ obj.bun }}
          </span>
          /
          <span :class="$style.uah">
            <span :class="$style.line">≈</span>{{ obj.uah }}
          </span>
        </div>
      </div>
      <div v-if="obj.present" :class="$style.present">
        <p v-html="obj.present" />
      </div>
      <div v-if="!obj.present"></div>
      <div v-if="obj.saving" :class="$style.saving">
        <p v-html="obj.saving" />
      </div>
      <div v-if="!obj.saving"></div>
    </div>
    <Button
      :id="obj.targetName"
      :class="$style.button"
      @click="pay(obj.payId, obj.paymentLink)"
    >
      Оплатить
    </Button>
  </div>
</template>

<script>
import Button from '@/components/basic/Button'

import { mapMutations } from 'vuex'

export default {
  components: {
    Button
  },
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapMutations([
      'changeFormStatus',
      'changePaymentStatus',
      'setPaymentId'
    ]),

    pay(payId, link) {
      // Отключение яндекс метрики
      // this.$metrika.reachGoal(targetName);

      // Отключение модалки
      // this.changePaymentStatus(true)

      this.setPaymentId(payId)
      window.open(link)

      // Уходим от старонников (оплата)
      // setTimeout(() => {
      //   var script = document.getElementById(payId)

      //   var par = script.parentNode
      //   script.parentNode.style.overflow = 'hidden'

      //   var iframe = document.createElement('iframe')
      //   iframe.src =
      //     'https://invest-factory.getcourse.ru/pl/lite/widget/widget' +
      //     '?' +
      //     window.location.search.substring(1) +
      //     '&id=' +
      //     widgetId +
      //     '&ref=' +
      //     encodeURIComponent(document.referrer) +
      //     '&loc=' +
      //     encodeURIComponent(document.location.href)
      //   iframe.style.width = '100%'
      //   iframe.style.height = '0px'
      //   iframe.style.border = 'none'
      //   iframe.style.overflow = 'hidden'
      //   iframe.className = '455'
      //   iframe.id =
      //     '1f87838c8336493c41b3c83cfff7390f37124aa2' + '_' + iframe.className
      //   // name можно получить изнутри iframe
      //   iframe.name = iframe.className

      //   var iframeId = iframe.id

      //   var gcEmbedOnMessage = function(e) {
      //     var insertedIframe = document.getElementById(iframeId)
      //     if (!insertedIframe) {
      //       return
      //     }

      //     if (e.data.uniqName == payId) {
      //       if (e.data.height) {
      //         if (e.data.iframeName) {
      //           if (e.data.iframeName == iframe.name) {
      //             par.style.height = e.data.height + 'px'
      //             insertedIframe.style.height = e.data.height + 'px'
      //           }
      //         } else {
      //           par.style.height = e.data.height + 'px'
      //           insertedIframe.style.height = e.data.height + 'px'
      //         }
      //       }
      //     }
      //   }

      //   if (window.addEventListener) {
      //     window.addEventListener('message', gcEmbedOnMessage, false)
      //   } else if (window.attachEvent) {
      //     window.attachEvent('onmessage', gcEmbedOnMessage)
      //   } else {
      //     window['onmessage'] = gcEmbedOnMessage
      //   }

      //   script.parentNode.insertBefore(iframe, script)
      //   par.removeChild(script)

      //   var getLocation = function(href) {
      //     var l = document.createElement('a')
      //     l.href = href
      //     return l
      //   }

      //   var currentScript =
      //     document.currentScript ||
      //     (function() {
      //       var scripts = document.getElementsByTagName('script')
      //       return scripts[scripts.length - 1]
      //     })()

      //   var domain = getLocation(currentScript.src).hostname

      //   console.log(domain)
      // }, 0)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 39rem;
  padding: 2.5rem;
  margin: 0.5rem 1.5rem;
  border-radius: 1.5rem;
  background: $medium-blue;
  text-align: center;

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 0 2.5rem;
  }

  .period {
    @include subtitleBold();
  }

  .prices {
    position: relative;
    bottom: 1.5rem;
  }

  .discount {
    position: relative;
    @include subtitleBold();
    color: $red;
    text-decoration: line-through;

    &::after {
      position: relative;
      bottom: 1rem;
      left: 0.25rem;
      content: 'RUB';
      font-size: 0.75rem;
    }
  }

  .price {
    @include price();

    &::after {
      position: relative;
      bottom: 2.25rem;
      left: 0.25rem;
      content: 'RUB';
      font-size: 1.5rem;
    }
  }

  .convert {
    opacity: 0.5;
    @include subtitleBold();

    .bun {
      margin: 0 0.25rem 0 0;
      &::after {
        position: relative;
        bottom: 0.75rem;
        left: 0.25rem;
        content: 'BYN';
        font-size: 0.75rem;
      }
    }

    .uah {
      margin: 0 0 0 0.25rem;
      &::after {
        position: relative;
        bottom: 0.75rem;
        left: 0.25rem;
        content: 'UAH';
        font-size: 0.75rem;
      }
    }

    .line {
      margin: 0 0.15rem 0 0;
      font-weight: 400;
      font-size: 1.25rem;
    }
  }

  .present {
    padding: 1rem;
    background: $green;
    border-radius: 1rem;
    @include smalltext();
    line-height: 1rem;
    color: $white;

    &__img {
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
    }
  }

  .saving {
    @include smalltext();
    line-height: 1rem;

    span {
      font-weight: 700;
      color: $green;
    }
  }

  .button {
    width: 100%;
  }
}

@include for-mobile {
  .card {
    height: 37rem;
    padding: 2.5rem 1.5rem 1.5rem;
  }
}
</style>
