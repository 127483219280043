<template>
  <transition name="fade">
    <aside v-if="opened" :class="$style.modal">
      <div :class="$style.modal__bg" @click="close" />
      <div :class="$style.modal__window">
        <div v-if="!result" :class="$style.wrapper">
          <div :class="$style.inputWrapper">
            <input
              :class="$style.inputRange"
              :style="{
                background:
                  'linear-gradient(to right, #27AE60 0%, #27AE60 ' +
                  offset +
                  '%, #F4F4F4 ' +
                  offset +
                  '%, #F4F4F4 100%)'
              }"
              ref="range"
              type="range"
              :min="0"
              :value="step"
              :max="6"
            />
            <div :class="$style.list">
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 0 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 1 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 2 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 3 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 4 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 5 }]"
              />
              <span
                :class="[$style.ball, { [$style.ball_active]: step >= 6 }]"
              />
            </div>
          </div>
          <div :class="$style.title">
            {{ dataTest[step].question }}
          </div>
          <div
            :class="$style.question"
            v-for="(answer, index) in dataTest[step].answers"
            :key="index"
          >
            <Checkbox
              :value="activeAnswer.id === answer.id ? true : false"
              @input="setAnswer(answer)"
            />
            <span :class="$style.answer" @click="setAnswer(answer)">{{
              answer.text
            }}</span>
          </div>
          <div :class="$style.buttons">
            <Button
              v-if="step < 6"
              :class="{
                [$style.disabled]: Object.keys(activeAnswer).length === 0
              }"
              @click="nextStep"
            >
              Дальше
            </Button>
            <Button
              v-else
              :class="{
                [$style.disabled]: Object.keys(activeAnswer).length === 0
              }"
              @click="showResult"
            >
              Показать Результат
            </Button>
            <Button type="primary" @click="close">Выйти</Button>
          </div>
        </div>
        <div v-else :class="[$style.wrapper, $style.wrapper__result]">
          <div :class="$style.title">{{ dataResults[index].title }}</div>
          <p :class="$style.text">
            {{ dataResults[index].text }}
          </p>
          <Button v-if="score >= 25" @click="ready">Я готов!</Button>
          <Button v-else type="primary" @click="close">Выйти</Button>
        </div>
      </div>
    </aside>
  </transition>
</template>

<script>
import Checkbox from '@/components/basic/Checkbox'
import Button from '@/components/basic/Button'

import test from '@/application/test'

import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    Checkbox,
    Button
  },

  data() {
    return {
      step: 0,
      activeAnswer: {},
      offset: 0,
      index: 0
    }
  },

  computed: {
    ...mapGetters({
      score: 'score',
      result: 'result',
      opened: 'opened'
    }),

    dataTest() {
      return test.TESTS
    },

    dataResults() {
      return test.RESULTS
    }
  },

  methods: {
    ...mapMutations([
      'setScore',
      'anulScore',
      'changeResultStatus',
      'changeOpenedStatus'
    ]),

    setAnswer(answer) {
      this.activeAnswer = answer
    },

    nextStep() {
      this.step += 1
      this.setScore(this.activeAnswer.score)
      this.activeAnswer = {}
      setTimeout(() => {
        this.offset =
          ((this.$refs.range.value - this.$refs.range.min) /
            (this.$refs.range.max - this.$refs.range.min)) *
          100
      }, 0)
    },

    close() {
      this.step = 0
      this.offset = 0
      this.activeAnswer = {}
      this.anulScore()
      this.changeResultStatus(false)
      this.changeOpenedStatus(false)
    },

    ready() {
      this.close()
      this.$scrollTo('#subscription', 500, {})
    },

    showResult() {
      this.setScore(this.activeAnswer.score)
      this.resultIndex()
      this.changeResultStatus(true)
    },

    resultIndex() {
      if (this.score === 35) {
        this.index = 2
      } else if (this.score >= 25 && this.score < 35) {
        this.index = 1
      } else {
        this.index = 0
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  &__bg {
    width: 100%;
    height: 100%;
    background: rgba($color: #122344, $alpha: 0.6);
  }

  &__window {
    position: fixed;
    top: 16%;
    left: 25%;
    display: flex;
    align-items: center;
    width: 50%;
    height: 30rem;
    padding: 2.5rem;
    border-radius: 1.5rem;
    background: $white;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      &__result {
        align-items: center;
        height: 70%;

        .text {
          @include text();
          line-height: 1.5rem;
          text-align: center;
        }
      }
    }

    .inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 1.5rem;
    }

    .inputRange {
      width: 100%;
      height: 0.375rem;
      border-radius: 0.625rem;
      outline: none;
      background: linear-gradient(
        to right,
        #27ae60 0%,
        #27ae60 50%,
        #f4f4f4 50%,
        #f4f4f4 100%
      );
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: all 0.3s ease-in;
      pointer-events: none;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb {
        border: none;
        -webkit-appearance: none;
      }
    }

    .list {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .ball {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background: $light-gray;
        transition: all 0.3s ease-in-out;

        &_active {
          background: $green;
        }
      }
    }

    .title {
      margin: 1rem 0 0;
      @include subtitle();
      font-size: 2rem;
      text-align: center;
      font-weight: 700;
    }

    .question {
      display: flex;
      align-items: flex-start;

      .answer {
        width: 90%;
        margin: 0 0 0 1.25rem;
        @include text();
        line-height: 1.5rem;
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

@include for-tablet {
  .modal {
    &__window {
      left: 5%;
      width: 90%;
    }
  }
}

@include for-mobile {
  .modal {
    &__window {
      top: 9%;
      height: 35rem;
      padding: 2rem 1rem 1.5rem;

      .wrapper {
        &__result {
          height: 90%;
        }
      }

      .inputRange {
        height: 0.25rem;
      }

      .list {
        .ball {
          width: 0.5rem;
          height: 0.5rem;
        }
      }

      .title {
        font-size: 1.5rem;
      }

      .buttons {
        flex-direction: column;
        height: 5.5rem;
      }
    }
  }
}
</style>
