<template>
  <section :class="$style.introduction">
    <img :class="$style.image" src="@/assets/images/introduction.png" />
    <Title>Закрытое сообщество инвесторов</Title>
    <p :class="$style.description">
      где каждый участник получает поддержку, обучается и общается в кругу
      единомышленников. Это помогает нам держать фокус на достижении финансовой
      независимости<span :class="$style.star">*</span>
    </p>
    <Button v-scroll-to="'#subscription'" :class="$style.button"
      >Вступить в Сообщество</Button
    >
    <p :class="$style.text">
      <span :class="$style.star">*</span> - пассивный доход, размер которого
      превышает расходы
    </p>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'
export default {
  components: {
    Title,
    Button
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.introduction {
  position: relative;
  max-width: 90rem;
  margin: 0 auto;
  min-height: 42.5rem;
  padding: 8.5rem 7.5rem;

  .description {
    width: 70%;
    margin: 1.5rem 0 2.4rem;
    @include subtitle();
    line-height: 2rem;
  }

  .text {
    margin: 7.75rem 0 0;
    @include text();
  }

  .star {
    color: $blue;
  }

  .image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

@include for-tablet {
  .introduction {
    padding: 8.5rem 1.5rem;

    .image {
      bottom: 6rem;
      right: -12.5rem;
    }
  }
}

@include for-mobile {
  .introduction {
    overflow: hidden;
    padding: 18rem 1.5rem 6.25rem;

    .image {
      bottom: auto;
      top: -3.75rem;
      right: -5.25rem;
      width: 23rem;
      height: 23rem;
    }

    .description {
      width: 100%;
      margin: 1rem 0 2.5rem;
      line-height: 1.5rem;
      font-size: 1rem;
    }

    .button {
      width: 100%;
    }

    .text {
      margin: 2.5rem 0 0;
      font-size: 0.75rem;
    }
  }
}
</style>
