<template>
  <transition name="fade">
    <aside v-if="payment" :class="$style.modal">
      <div :class="$style.modal__bg" @click="close" />
      <div :class="$style.modal__window">
        <div v-if="paymentId" :id="paymentId"></div>
      </div>
    </aside>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      payment: 'payment',
      paymentId: 'paymentId'
    })
  },
  methods: {
    ...mapMutations(['changePaymentStatus', 'setPaymentId']),
    close() {
      this.changePaymentStatus(false)
      this.setPaymentId(null)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  &__bg {
    width: 100%;
    height: 100%;
    background: rgba($color: #122344, $alpha: 0.6);
  }

  &__window {
    position: fixed;
    top: 16%;
    left: 25%;
    display: flex;
    align-items: center;
    width: 50%;
    height: 461px;
    padding: 2.5rem;
    border-radius: 1.5rem;
    background: $white;
  }
}

@include for-tablet {
  .modal {
    &__window {
      left: 5%;
      width: 90%;
    }
  }
}

@include for-mobile {
  .modal {
    &__window {
      top: 12%;
      padding: 0;
    }
  }
}
</style>
