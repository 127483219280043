<template>
  <section :class="$style.advantages">
    <div :class="$style.wrapper">
      <Title :class="$style.title">Уже через неделю</Title>
      <p :class="$style.description">
        после вступления в наше инвест-сообщество вы выработаете свою стратегию
        инвестирования, подберете себе удобного брокера, сформируете
        <strong> портфель акций, облигаций и криптовалют </strong> (при желании)
        и начнете инвестировать <strong> без страха </strong> и прокрастинации.
      </p>
      <Button v-scroll-to="'#subscription'" :class="$style.button"
        >Как к нам попасть</Button
      >
      <img :class="$style.icon" src="@/assets/icons/advantage-1.svg" />
    </div>
    <div :class="$style.wrapper">
      <Title :class="$style.title">Это сообщество для Вас, если</Title>
      <p :class="$style.description">
        вы уже имеете базовые знания о инвестициях и планируете начать регулярно
        инвестировать в акции и/или криптовалюты. Пройдите наш тест на знания,
        чтобы убедиться, что сообщество Вам подходит.
      </p>
      <Button :class="$style.button" @click="changeOpenedStatus(true)"
        >Пройти Тест</Button
      >
      <img :class="$style.icon" src="@/assets/icons/advantage-2.svg" />
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'

import { mapMutations } from 'vuex'

export default {
  components: {
    Title,
    Button
  },
  methods: {
    ...mapMutations(['changeOpenedStatus'])
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.advantages {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 7.5rem;

  .wrapper {
    position: relative;
    padding: 0.5rem 0 7.5rem;

    &:last-child {
      margin: 5rem 0 0;
      padding: 2.4rem 0 7.5rem 41.5%;

      .description {
        width: 92%;
      }

      .icon {
        left: 0;
      }
    }

    .description {
      width: 50%;
      margin: 1.5rem 0 2.5rem;
      @include subtitle();
      line-height: 2rem;
    }

    .icon {
      position: absolute;
      bottom: 0;
      right: 4.5rem;
    }
  }
}

@include for-tablet {
  .advantages {
    padding: 0 1.5rem;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        margin: 0;
        padding: 0;

        .description {
          width: 70%;
        }
      }

      .title {
        text-align: center;
      }

      .description {
        width: 70%;
        text-align: center;
      }

      .button {
        margin: 0 0 2rem;
      }

      .icon {
        position: static;
      }
    }
  }
}

@include for-mobile {
  .advantages {
    .wrapper {
      padding: 0.5rem 0 6rem;

      &:last-child {
        .description {
          width: 100%;
        }

        .icon {
          width: 100%;
        }
      }

      .description {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .button {
        width: 100%;
        margin: 0 0 2.75rem;
      }

      .icon {
        width: 85%;
      }
    }
  }
}
</style>
