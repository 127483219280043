<template>
  <section :class="$style.about" id="about">
    <Title position="center">Основатель “Мастерской инвестиций”</Title>
    <div :class="$style.content">
      <div :class="$style.photo">
        <img :class="$style.img" src="@/assets/images/avatar.png" />
      </div>
      <div :class="$style.info">
        <h3 :class="$style.title">Юрий Шурханов</h3>
        <p :class="$style.position">
          Инвестор, блогер, писатель, предприниматель в IT-сфере.
        </p>
        <p :class="$style.description">
          Имеет многолетний опыт в инвестировании и трейдинге. Автор
          образовательных курсов и программ по инвестированию в акции и
          криптовалюты. Имеет образование по специальности “Финансовое
          консультирование”.
          <a
            :class="$style.link"
            href="https://telegra.ph/Zakrytyj-invest-pul-05-20"
            target="_blank"
            >Подробнее</a
          >
        </p>
        <img
          :class="$style.instagram"
          src="@/assets/icons/instagram.svg"
          @click="goTo"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
export default {
  components: {
    Title
  },
  methods: {
    goTo() {
      location.href = 'https://www.instagram.com/yuriy_shurhanov/?hl=ru'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90rem;
  margin: 5.5rem auto;
  padding: 1.5rem 7.5rem;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 76.5%;
    margin: 5rem 0 0;

    .photo {
      width: 30.5%;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: 56.5%;

      .title {
        @include subtitle();
      }

      .position {
        margin: 1.75rem 0;
        @include text();
      }

      .description {
        margin: 1.5rem 0;
        @include text();
        line-height: 1.5rem;

        .link {
          text-decoration: none;
          color: $blue;
          font-weight: 700;
        }
      }

      .instagram {
        cursor: pointer;
      }
    }
  }
}

@include for-tablet {
  .about {
    margin: 0 auto;
    padding: 0 1.5rem 0.5rem;

    .content {
      flex-direction: column;
      width: 100%;
      margin: 2.7rem 0 0;

      .photo {
        width: 50%;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        padding: 2.35rem 0;

        .title {
          text-align: center;
        }

        .position {
          margin: 1.5rem 0;
          text-align: center;
          line-height: 1.5rem;
        }

        .description {
          margin: 0 0 1.5rem;
          text-align: center;
        }
      }
    }
  }
}

@include for-mobile {
  .about {
    .content {
      .photo {
        width: 90%;
      }

      .info {
        width: 100%;
      }
    }
  }
}
</style>
