<template>
  <div :class="$style.card">
    <h3 :class="$style.title">{{ obj.title }}</h3>
    <p :class="$style.text" v-html="obj.text" />
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.card {
  width: 21.5rem;
  height: 15.5rem;
  padding: 2rem;
  margin: 5rem 0 0;
  background: $white;
  box-shadow: 0px 1.5rem 2.5rem rgba(18, 35, 68, 0.06);
  border-radius: 1.5rem;

  .title {
    margin: 0 0 1rem;
    @include subtitle();
    font-weight: 700;
    line-height: 2rem;
  }

  .text {
    @include text();
    line-height: 1.5rem;
  }
}

@include for-mobile {
  .card {
    width: 100%;
    height: 100%;
    margin: 3rem 0 0;
    padding: 1rem 1.5rem 1.5rem;

    .text {
      font-size: 1rem;
    }
  }
}
</style>
