<template>
  <div :class="$style.card">
    <div :class="$style.icon">
      <img :src="require(`@/assets/icons/${obj.icon}.svg`)" />
    </div>
    <div :class="$style.content">
      <h3 :class="$style.title">{{ obj.title }}</h3>
      <div :class="$style.items">
        <div :class="$style.wrapper" v-for="item in obj.items" :key="item">
          <p :class="$style.item" v-html="item" />
        </div>
      </div>
      <img
        :class="$style.image"
        :src="require(`@/assets/images/${obj.image}.png`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.card {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 0 2.5rem;

  .icon {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 1rem 1.5rem rgba(18, 35, 68, 0.12);
  }

  .image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .content {
    width: 94%;
    min-height: 12rem;
    padding: 2.5rem 0 1rem 4.5rem;
    background: $blue;
    border-radius: 1.5rem;
    box-shadow: 0 1.5rem 2.5rem rgba(18, 35, 68, 0.12);

    .title {
      margin: 0 0 1.75rem;
      padding: 0 2.5rem;
      @include subtitle();
      color: $white;
    }

    .items {
      display: flex;
      flex-wrap: wrap;

      .wrapper {
        display: flex;
        align-items: center;
        width: 33%;
        height: 3.5rem;
        margin: 0 0 1.5rem;

        &:nth-child(2) {
          border-left: 1px solid rgba($color: $white, $alpha: 0.3);
        }

        &:nth-child(3) {
          border-left: 1px solid rgba($color: $white, $alpha: 0.3);
        }

        &:nth-child(5) {
          border-left: 1px solid rgba($color: $white, $alpha: 0.3);
        }

        &:nth-child(6) {
          border-left: 1px solid rgba($color: $white, $alpha: 0.3);
        }
      }

      .item {
        padding: 0 2.5rem;
        @include text();
        color: $white;
      }
    }
  }
}

@include for-tablet {
  .card {
    .icon {
      width: 7rem;
      height: 7rem;
    }

    .content {
      .wrapper {
        .item {
          padding: 0 1rem;
        }
      }
    }
  }
}

@include for-mobile {
  .card {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 7rem;

    .icon {
      top: -4.5rem;
      left: auto;
      right: auto;
      width: 9rem;
      height: 9rem;
    }

    .image {
      right: -3.75rem;
      height: 3.25rem;
    }

    .content {
      width: 100%;
      padding: 6rem 0 2.25rem;

      .title {
        margin: 0 0 1.25rem;
        padding: 0;
        text-align: center;
      }

      .items {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;

        .wrapper {
          position: relative;
          justify-content: center;
          width: 82%;
          margin: 0 0 2.6rem;

          &::before {
            position: absolute;
            bottom: -1.4rem;
            right: auto;
            left: auto;
            content: '';
            width: 5rem;
            height: 0.063rem;
            background: $white;
            opacity: 0.3;
          }

          &:nth-child(n + 2) {
            border-left: none;
          }

          &:last-child {
            margin: 0;

            &::before {
              display: none;
            }
          }
        }

        .item {
          padding: 0;
          text-align: center;
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
