export default Object.freeze({
  TESTS: [
    {
      id: 1,
      question: 'Биржа - это...',
      answers: [
        {
          id: 1,
          text: 'Рынок ценных бумаг.',
          score: 0
        },
        {
          id: 2,
          text:
            'Организатор торгов товарами, валютой, ценными бумагами, производными и другими рыночными инструментами.',
          score: 5
        },
        {
          id: 3,
          text:
            'Место, где все переговариваются или кричат, чтобы купить акции.',
          score: 0
        }
      ]
    },
    {
      id: 2,
      question: 'Тикер – это...',
      answers: [
        {
          id: 1,
          text: 'Краткое название компании, акции которой торгуются на бирже.',
          score: 5
        },
        {
          id: 2,
          text: 'Билет, который необходимо купить, чтобы торговать на бирже.',
          score: 0
        },
        {
          id: 3,
          text:
            'Название акций, которые доступны для торговли не квалифицированному инвестору.',
          score: 0
        }
      ]
    },
    {
      id: 3,
      question: 'Кто такой инвестор?',
      answers: [
        {
          id: 1,
          text:
            'Это человек, который вкладывает деньги с целью извлечения прибыли.',
          score: 0
        },
        {
          id: 2,
          text: 'Тот, кто производит инвестицию.',
          score: 0
        },
        {
          id: 3,
          text:
            'Участник (физлицо или организация) инвестиционной деятельности, который вкладывает свои или заемные средства в проект.',
          score: 0
        },
        {
          id: 4,
          text: 'Все варианты ответа верны.',
          score: 5
        }
      ]
    },
    {
      id: 4,
      question: 'Что такое облигация?',
      answers: [
        {
          id: 1,
          text:
            'Это долговая ценная бумага, по которой  компания или государство обязуется выплатить покупателю таких бумаг определенную сумму и определенный процент в будущем.',
          score: 5
        },
        {
          id: 2,
          text: 'Это чек, который выдают после покупки акций.',
          score: 0
        },
        {
          id: 3,
          text:
            'Это акции или ценные бумаги, которые можно купить в определенный период времени.',
          score: 0
        }
      ]
    },
    {
      id: 5,
      question: 'Что такое S&P 500?',
      answers: [
        {
          id: 1,
          text: 'Это проба золота.',
          score: 0
        },
        {
          id: 2,
          text: 'Индексный фонд, в который входят 500 компаний.',
          score: 5
        },
        {
          id: 3,
          text: 'Название американской компании.',
          score: 0
        }
      ]
    },
    {
      id: 6,
      question: 'Что такое ETF?',
      answers: [
        {
          id: 1,
          text: 'Это фонды, чьи акции можно купить и продать на бирже.',
          score: 5
        },
        {
          id: 2,
          text: 'Сокращенное название компании.',
          score: 0
        },
        {
          id: 3,
          text: 'Молодежная сленговая аббревиатура.',
          score: 0
        }
      ]
    },
    {
      id: 7,
      question: 'Какой из предложенных вариантов относится к ETF-фондам?',
      answers: [
        {
          id: 1,
          text: 'ГАЗПРОМ',
          score: 0
        },
        {
          id: 2,
          text: 'FXBB',
          score: 5
        },
        {
          id: 3,
          text: 'АЭРОФЛОТ',
          score: 0
        }
      ]
    }
  ],
  RESULTS: [
    {
      id: 1,
      title: 'Не очень хороший результат :(',
      text:
        'К сожалению, тест показал, что Ваших знаний пока недостаточно. Мы не хотим, чтобы вы чувствовали себя некомфортно в сообществе. Поэтому предлагаем, для начала, приобрести базовые навыки по инвестициям. После этого ваше пребывание в сообществе будет максимально комфортным и эффективным.'
    },
    {
      id: 2,
      title: 'У Вас хороший результат :)',
      text:
        'Мы будем рады видеть Вас в сообществе инвесторов! У Вас есть некоторые пробелы в знаниях, но не переживайте: на нашей обучающей платформе вы их заполните.'
    },
    {
      id: 3,
      title: 'У Вас отличный результат!',
      text:
        'Супер, все ответы верны! Будем рады видеть Вас в “Мастерской инвестиций”.'
    }
  ]
})
