<template>
  <header :class="$style.header">
    <img :class="$style.logo" src="@/assets/icons/logo.svg" />
    <nav :class="$style.nav">
      <span :class="$style.link" v-scroll-to="{ el: '#goals', offset: -100 }">
        Цели
      </span>
      <span
        :class="$style.link"
        v-scroll-to="{ el: '#consists', offset: -100 }"
      >
        О сообществе
      </span>
      <span :class="$style.link" v-scroll-to="'#subscription'">Подписка</span>
      <span :class="$style.link" v-scroll-to="{ el: '#success', offset: -100 }">
        Успех
      </span>
      <span :class="$style.link" v-scroll-to="{ el: '#about', offset: -150 }">
        Об авторе
      </span>
    </nav>
    <img
      :class="$style.burger"
      :src="require(`@/assets/icons/${menu ? 'close' : 'burger'}.svg`)"
      @click="openMobileMenu"
    />
    <transition name="rightExit">
      <nav v-if="menu" :class="$style.nav__mobile">
        <span :class="$style.link" @click="goTo('#goals', -100)"> Цели </span>
        <span :class="$style.link" @click="goTo('#consists', -100)">
          О сообществе
        </span>
        <span :class="$style.link" @click="goTo('#subscription', -50)"
          >Подписка</span
        >
        <span :class="$style.link" @click="goTo('#success', -50)"> Успех </span>
        <span :class="$style.link" @click="goTo('#about', -70)">
          Об авторе
        </span>
        <Button :class="$style.button" @click="goTo('#subscription', -50)"
          >Вступить в Сообщество</Button
        >
      </nav>
    </transition>
  </header>
</template>

<script>
import Button from '@/components/basic/Button'
export default {
  components: {
    Button
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    goTo(path, offset) {
      this.menu = false
      document.body.style.overflow = 'visible'
      this.$scrollTo(path, 500, { offset: offset })
    },
    openMobileMenu() {
      if (this.menu) {
        this.menu = false
        document.body.style.overflow = 'visible'
      } else {
        this.menu = true
        document.body.style.overflow = 'hidden'
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.5rem 7.5rem;
  background: $white;
  z-index: 100;

  .logo {
    width: 39%;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    width: 43%;

    &__mobile {
      display: none;
    }

    .link {
      @include link();
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        color: $blue;
        transform: translateY(-0.15rem);
      }
    }
  }

  .burger {
    display: none;
  }
}

@include for-tablet {
  .header {
    padding: 1.5rem;

    .logo {
      width: 35%;
    }

    .nav {
      width: 61%;
    }
  }
}

@include for-mobile {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4rem;
    padding: 0.75rem 1.5rem;

    .logo {
      width: 18rem;
    }

    .nav {
      display: none;

      &__mobile {
        position: fixed;
        top: 4rem;
        right: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 3.25rem 1.5rem;
        background: $white;

        .link {
          margin: 0 0 1.75rem;
          @include subtitle();
          font-weight: 700;
        }

        .button {
          margin: 10.75rem 0 0;
        }
      }
    }

    .burger {
      display: block;
      width: 7%;
    }
  }
}
</style>
