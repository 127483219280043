<template>
  <section :class="$style.ourGoals" id="goals">
    <Title position="center">Наши общие цели</Title>
    <div :class="$style.list">
      <Card v-for="goal in goalsData" :key="goal.id" :obj="goal" />
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Card from '@/components/common/OurGoalsCard'

import { goals } from '@/application/goals'

export default {
  components: {
    Title,
    Card
  },
  computed: {
    goalsData() {
      return goals
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.ourGoals {
  max-width: 90rem;
  margin: 5rem auto;
  padding: 0 5rem;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@include for-tablet {
  .ourGoals {
    margin: 0 auto;
    padding: 0 1.5rem;
  }
}
</style>
