<template>
  <label :class="$style.customCheckbox">
    <input
      :class="$style.customCheckbox__checkbox"
      type="checkbox"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
    />
    <div :class="$style.customCheckbox__label" />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.customCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__checkbox {
    display: none;
  }

  &__checkbox:checked + &__label {
    border-color: $blue;

    &:after {
      transform: scale(1) !important;
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }

  &__label {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid $grey;
    border-radius: 100%;

    &:after {
      content: '';
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      left: 0.125rem;
      right: 0.125rem;
      top: 0.125rem;
      bottom: 0.125rem;
      border-radius: 100%;
      background: $blue;
      transform: scale(0);
      transition: all 0.2s ease;
      pointer-events: none;
      opacity: 0.08;
    }
  }

  &:hover {
    .customCheckbox__label {
      &::after {
        transform: scale(2.5);
      }
    }
  }
}
</style>
