export const consists = [
  {
    id: 1,
    icon: 'consists-1',
    image: 'analytics',
    title: 'Цех аналитики',
    items: [
      'Две инвест-идеи <br> в неделю',
      'Еженедельный обзор <br> рынка акций',
      'Еженедельный обзор <br> рынка криптовалют',
      'Торговые “сигналы” <br> по криптовалютам',
      'Экстренные обзоры рынков <br> при важных событиях'
    ]
  },
  {
    id: 2,
    icon: 'consists-2',
    image: 'education',
    title: 'Обучающий цех',
    items: [
      'Обучение начинающих <br> инвесторов',
      'Обучение продвинутых <br> инвесторов',
      'Обучение торговле <br> криптовалютами',
      'Обучение <br> основам трейдинга',
      'Библиотека <br> инвестора',
      'Психология и <br> мышление инвестора'
    ]
  },
  {
    id: 3,
    icon: 'consists-3',
    image: 'communications',
    title: 'Цех коммуникаций',
    items: [
      'Чат для обсуждения рынка <br> акций/облигаций',
      'Чат для обсуждения <br> рынка криптовалют',
      'Чат помощи <br> по всем вопросам'
    ]
  },
  {
    id: 4,
    icon: 'consists-4',
    image: 'news',
    title: 'Новостной цех',
    items: [
      'Канал только <br> важных новостей',
      'Отслеживание важных событий <br> (заседания ФРС и тп)'
    ]
  }
]
