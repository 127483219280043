<template>
  <footer :class="$style.footer">
    <div :class="$style.content">
      <img :class="$style.logo" src="@/assets/icons/logo-footer.svg" />
      <img
        :class="$style.logo__mobile"
        src="@/assets/icons/logo-footer-mb.svg"
      />
      <div :class="$style.flex">
        <div :class="$style.wrapper">
          <p :class="$style.text">
            Напишите
            <span :class="$style.text__link" @click="goTo('https://t.me/Efexs')"
              >в наш отдел заботы</span
            >, если не пришло письмо после оплаты или возникли другие вопросы -
            мы поможем
          </p>
        </div>
        <div :class="$style.wrapper">
          <span :class="$style.text">
            <img
              :class="$style.instagram"
              src="@/assets/icons/instagram-white.svg"
              @click="goTo('https://www.instagram.com/yuriy_shurhanov/?hl=ru')"
            />
          </span>
          <router-link
            :class="[$style.text, $style.offer]"
            to="/contract"
            exact
          >
            Договор Оферты
          </router-link>
          <div :class="[$style.text, $style.text__flex]">
            <span :class="$style.text__requisites">ФОП Шурханов Юрий</span>
            <span :class="$style.ball" />
            <span :class="$style.text__requisites">ЄДРПОУ/ІПН: 3228126571</span>
          </div>
        </div>
      </div>
    </div>
    <img :class="$style.img" src="@/assets/images/footer.png" />
  </footer>
</template>

<script>
export default {
  methods: {
    goTo(link) {
      location.href = link
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.footer {
  position: relative;
  width: 100%;
  height: 23rem;
  background: $dark-blue;

  .content {
    position: relative;
    max-width: 90rem;
    margin: 0 auto;
    padding: 5rem 7.5rem;
    z-index: 10;

    .logo {
      &__mobile {
        display: none;
      }
    }

    .flex {
      display: flex;
      justify-content: space-between;

      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        height: 7.5rem;
        margin: 2.5rem 0 0;

        &:last-child {
          align-items: flex-end;
        }

        .text {
          @include text();
          color: $white;
          line-height: 150%;

          &__link {
            position: relative;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              color: $blue;

              &::before {
                width: 0%;
              }
            }

            &::before {
              position: absolute;
              bottom: 0;
              right: 0;
              content: '';
              width: 100%;
              height: 1px;
              background: $white;
              transition: all 0.2s ease;
            }
          }

          &__flex {
            display: flex;
            align-items: center;
          }

          .ball {
            display: block;
            width: 0.25rem;
            height: 0.25rem;
            margin: 0 0.65rem;
            background: $white;
            border-radius: 50%;
          }

          .instagram {
            cursor: pointer;
          }
        }

        .offer {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  .img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
}

@include for-tablet {
  .footer {
    .content {
      padding: 5rem 1.5rem;

      .flex {
        .wrapper {
          width: 50%;
          .text {
            &__requisites {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

@include for-mobile {
  .footer {
    height: 33.5rem;

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 3rem 1.5rem;

      .logo {
        display: none;

        &__mobile {
          display: block;
        }
      }

      .flex {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        .wrapper {
          width: 100%;
          height: 4.75rem;

          &:last-child {
            align-items: center;
            height: 8rem;
          }

          .text {
            text-align: center;

            &:first-child {
              order: 1;
            }

            &:nth-child(3) {
              order: 2;
            }

            .ball {
              opacity: 0;
            }
          }
        }
      }
    }

    .img {
      width: 70%;
    }
  }
}
</style>
