<template>
  <section :class="$style.subscription" id="subscription">
    <Title position="center" color="white">Подписка</Title>
    <div :class="$style.dots">
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 0 }
        ]"
      />
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 1 }
        ]"
      />
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 2 }
        ]"
      />
    </div>
    <VueSlickCarousel
      :class="$style.carousel"
      v-bind="settings"
      ref="carousel"
      @afterChange="swipeSlide"
    >
      <div
        :class="$style.slider"
        v-for="card in subscriptionsData"
        :key="card.id"
      >
        <Card :obj="card" />
      </div>
    </VueSlickCarousel>
    <div :class="$style.dots">
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 0 }
        ]"
      />
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 1 }
        ]"
      />
      <i
        :class="[
          $style.dots__ball,
          { [$style.dots__ball_active]: activeSlide === 2 }
        ]"
      />
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Card from '@/components/common/SubscriptionCard'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { subscriptions } from '@/application/subscriptions'

export default {
  components: {
    Title,
    Card
  },
  computed: {
    subscriptionsData() {
      return subscriptions
    }
  },
  data() {
    return {
      activeSlide: 0,
      settings: {
        arrows: false,
        dots: false,
        draggable: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              draggable: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              draggable: true,
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    swipeSlide(e) {
      this.activeSlide = e
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.subscription {
  position: relative;
  width: 100%;
  height: 62.5rem;
  padding: 7.5rem 0 0;
  background: $dark-blue;

  .dots {
    display: none;
    justify-content: center;
    margin: 2.5rem 0;

    &__ball {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.5rem;
      background: $white;
      border-radius: 50%;
      opacity: 0.5;

      &_active {
        opacity: 1;
      }
    }
  }

  .carousel {
    max-width: 77rem;
    margin: 5rem auto;

    .slider {
      outline: none;
    }
  }
}

@include for-tablet {
  .subscription {
    .dots {
      display: flex;
    }

    .carousel {
      margin: 0 auto;
    }
  }
}

@include for-mobile {
  .subscription {
    height: 53rem;
    padding: 3rem 0 0;

    .dots {
      margin: 1.5rem 0;
    }
  }
}
</style>
