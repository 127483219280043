export const goals = [
  {
    id: 1,
    title: 'Инвестировать в акции компаний',
    text:
      'доходность по которым может превышать рост индекса <strong>  S&P 500 </strong>  - главный ориентир успешности вложений для любого инвестора.'
  },
  {
    id: 2,
    title: 'Инвестировать в цифровые активы',
    text:
      '<strong> - криптовалюты и DeFi </strong>  - для получения прибыли на современных технологиях.'
  },
  {
    id: 3,
    title: 'Обучаться и прокачивать свои навыки',
    text:
      'инвестирования, финансового мышления, ведения личного или семейного бюджета.'
  },
  {
    id: 4,
    title: 'Общаться в кругу единомышленников',
    text: 'обсуждать финансовые вопросы обмениваться различными мнениями.'
  },
  {
    id: 5,
    title: 'Получать поддержку',
    text: 'и мнение опытных специалистов, если вдруг возникают вопросы.'
  },
  {
    id: 6,
    title: 'Фокусироваться на достижении',
    text:
      'финансовой независимости и получении максимальной прибыли от вложений.'
  }
]
