<template>
  <div>
    <Header />
    <main>
      <Introduction />
      <OurGoals />
      <Consists />
      <Advantages />
      <Subscription />
      <Success />
      <About />
      <ModalTest />
      <ModalPayment />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import Introduction from '@/components/Introduction'
import OurGoals from '@/components/OurGoals'
import Consists from '@/components/Consists'
import Advantages from '@/components/Advantages'
import Subscription from '@/components/Subscription'
import Success from '@/components/Success'
import About from '@/components/About'
import Footer from '@/components/common/Footer'
import ModalTest from '@/components/ModalTest'
import ModalPayment from '@/components/ModalPayment'

export default {
  name: 'Landing',
  components: {
    Header,
    Introduction,
    OurGoals,
    Consists,
    Advantages,
    Subscription,
    Success,
    About,
    Footer,
    ModalTest,
    ModalPayment
  }
}
</script>
