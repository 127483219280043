export const subscriptions = [
  {
    id: 1,
    period: '3 месяца',
    rub: 3790,
    bun: 126,
    uah: 1377,
    payId: 'a3d97996aa207d874b62b4d23df1306b06e3546c',
    widgetId: '497507',
    targetName: 'sub3m',
    paymentLink: 'https://pay.fondy.eu/s/AUuELNQFZFSY'
  },
  {
    id: 2,
    period: '6 месяцев',
    discount: 7580,
    rub: 6550,
    bun: 217,
    uah: 2379,
    saving:
      '<span>Экономия 13,6%</span>. Ровно на столько ежегодно рос индекс S&P 500 в последние 10 лет (средняя величина). Сэкономил - значит заработал.',
    payId: '33216e45a90474f8c0b3a134da0abba8087f4ee0',
    widgetId: '497521',
    targetName: 'sub6m',
    paymentLink: 'https://pay.fondy.eu/s/ZDpmAHazMY0KgBJ'
  },
  {
    id: 3,
    period: '1 год',
    discount: 15160,
    rub: 10990,
    bun: 363,
    uah: 3992,
    present:
      '<strong>+1 аккаунт</strong> - возможность бесплатно добавить в сообщество вашего родственника или друга также на 1 год.',
    saving:
      '<span>Экономия 27,2%</span>. Это в 2 раза выше роста индекса S&P 500 (средний рост за последние 10 лет - 13,6% ). Покупка подписки на год - отличная инвестиция.',
    payId: '0597cb5b9025eb188b961a96b27ad8d1b316854e',
    widgetId: '497929',
    targetName: 'sub1year',
    paymentLink: 'https://pay.fondy.eu/s/c18cA6Pz'
  }
]
