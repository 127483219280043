<template>
  <section :class="$style.consists" id="consists">
    <Title position="center">Из чего состоит инвест-сообщество</Title>
    <div :class="$style.list">
      <Card
        v-for="consists in consistsData"
        :key="consists.id"
        :obj="consists"
      />
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Card from '@/components/common/ConsistsCard'

import { consists } from '@/application/consists'

export default {
  components: {
    Title,
    Card
  },
  computed: {
    consistsData() {
      return consists
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.consists {
  max-width: 90rem;
  margin: 14.375rem auto;
  padding: 0 7.5rem;

  .list {
    margin: 4.75rem 0 0;
  }
}

@include for-tablet {
  .consists {
    margin: 6rem auto;
    padding: 0 1.5rem;
  }
}

@include for-mobile {
  .consists {
    margin: 6rem auto -1.5rem;

    .list {
      margin: 7rem 0 0;
    }
  }
}
</style>
