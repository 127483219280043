var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.subscription,attrs:{"id":"subscription"}},[_c('Title',{attrs:{"position":"center","color":"white"}},[_vm._v("Подписка")]),_c('div',{class:_vm.$style.dots},[_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj = {}, _obj[_vm.$style.dots__ball_active] = _vm.activeSlide === 0, _obj )
      ]}),_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj$1 = {}, _obj$1[_vm.$style.dots__ball_active] = _vm.activeSlide === 1, _obj$1 )
      ]}),_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj$2 = {}, _obj$2[_vm.$style.dots__ball_active] = _vm.activeSlide === 2, _obj$2 )
      ]})]),_c('VueSlickCarousel',_vm._b({ref:"carousel",class:_vm.$style.carousel,on:{"afterChange":_vm.swipeSlide}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.subscriptionsData),function(card){return _c('div',{key:card.id,class:_vm.$style.slider},[_c('Card',{attrs:{"obj":card}})],1)}),0),_c('div',{class:_vm.$style.dots},[_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj$3 = {}, _obj$3[_vm.$style.dots__ball_active] = _vm.activeSlide === 0, _obj$3 )
      ]}),_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj$4 = {}, _obj$4[_vm.$style.dots__ball_active] = _vm.activeSlide === 1, _obj$4 )
      ]}),_c('i',{class:[
        _vm.$style.dots__ball,
        ( _obj$5 = {}, _obj$5[_vm.$style.dots__ball_active] = _vm.activeSlide === 2, _obj$5 )
      ]})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }