<template>
  <section :class="$style.success" id="success">
    <div :class="$style.content">
      <Title :class="$style.title">Составляющие успеха в 21 веке</Title>
      <p :class="$style.text">
        Мы в <strong> “Мастерской инвестиций” </strong> работаем над
        формированием мотивирующего окружения и стабильного пассивного дохода.
      </p>
    </div>
    <div :class="$style.image">
      <img :class="$style.img" src="@/assets/images/success.png" />
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
export default {
  components: {
    Title
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.success {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 7.5rem;

  .content {
    width: 47%;

    .text {
      margin: 2.5rem 0 0;
      @include subtitle();
      line-height: 2rem;
    }
  }

  .image {
    width: 47%;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

@include for-tablet {
  .success {
    padding: 0 1.5rem;

    .content {
      margin: 3rem 0;
    }
  }
}

@include for-mobile {
  .success {
    flex-direction: column;

    .content {
      width: 100%;
      margin: 3rem 0 0;

      .title {
        text-align: center;
      }

      .text {
        margin: 1.5rem 0 0;
        text-align: center;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .image {
      position: relative;
      bottom: 1.35rem;
      width: 100%;
    }
  }
}
</style>
